import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import * as amplitude from '@amplitude/analytics-browser'
import {environment} from "../../../environments/environment";
import {Product} from "../../interfaces/product";
import {FooterService} from "../footer/footer.service";
// @ts-ignore
import {replay} from "./sessionReplay.js";
import {HomepageService} from "../homepage/homepage.service";
import {isPlatformBrowser} from "@angular/common";
import {Showroom} from "../../interfaces/showroom.interface";

declare type allProductPages = 'homepage' | 'local_items' | 'our_favorites' | 'space_page'


@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {

  constructor(
    public activatedRoute: ActivatedRoute,
    public footerService: FooterService,
    public homePageService: HomepageService,
    @Inject(PLATFORM_ID) public platformId: Object,
  ) { }

  /**
   * To initialize amplitude
   */
  async initializeAmplitude () {
    if(environment.sessionReplay) {
      const sessionReplayTracking = replay({sampleRate: 0.5});
      await amplitude.add(sessionReplayTracking).promise;
    }
    amplitude.init(environment.amplitudeKey, {
      defaultTracking: {
        sessions: true
      },
    })
  }

  /**
   * To send tracking event to amplitude API
   * @param eventName Event Name
   * @param eventProperties Event Properties
   */
  trackAmplitudeEvent(eventName: string, eventProperties: any) {
    // Add property id and company id to each amplitude event
    eventProperties = {
      ...eventProperties,
      property_id: this.footerService.PropertyDetails.id,
      account_id: this.footerService.PropertyDetails.companyId,
    }

    amplitude.track(eventName, eventProperties);

    setTimeout(() => {
      const deviceId = amplitude.getDeviceId();
      if(deviceId && !environment.isPreview) {
        this.homePageService.updateViewerCount({
          propertyId: this.footerService?.PropertyDetails?.id!,
          deviceId: deviceId,
        });
      }
    }, 1000)
  }

  /**
   * To track screen impression events
   * @param screenName Screen name
   * @param spaceName Space name
   */
  screenImpressionEvent(screenName: string, spaceName?: string) {
    const eventName = 'screen_impression'
    const queryParams = this.activatedRoute.snapshot.queryParams
    const eventObject = {
      screen_name: screenName,
      space_name: spaceName,
      utm_source: !!queryParams['utm_source'] ? queryParams['utm_source'] : undefined,
      utm_content: !!queryParams['utm_content'] ? queryParams['utm_content'] : undefined,
      utm_campaign: !!queryParams['utm_campaign'] ? queryParams['utm_campaign'] : undefined,
      utm_medium: !!queryParams['utm_medium'] ? queryParams['utm_medium'] : undefined,
    }

    this.trackAmplitudeEvent(eventName, eventObject)
  }

  /**
   * User clicks on a product - on mobile, this triggers the item drawer and on desktop, this opens PDP in new tab
   * @param productData Product Data
   * @param screenName Screen name
   * @param spaceName Space Name
   */
  productCardClickEvent(productData: Product, screenName: string, spaceName?: string) {
    const isMobile = isPlatformBrowser(this.platformId) && window.innerWidth < 767;
    const eventName = isMobile ? 'product_card_click' : 'product_shop_now_click'
    const eventObject = {
      product_name: productData.productName,
      url: productData.affiliateUrl ? productData.affiliateUrl : productData.productUrl,
      website_brand_name: productData.brandName,
      screen_name: screenName,
      space_name: spaceName,
    }
    this.trackAmplitudeEvent(eventName, eventObject);
    if(!environment.isPreview && !isMobile) {
      this.homePageService.clickAndViewerUpdate(this.clickAndViewPostData('click')).subscribe();
    }
  }

  /**
   * User clicks "View all" on homepage carousel
   * @param spaceName Space name of carousel
   */
  productCarouselViewAllClick(spaceName: string) {
    const eventName = 'product_carousel_view_all_click'
    const eventObject = {
      space_name: spaceName
    }
    this.trackAmplitudeEvent(eventName, eventObject)
  }

  /**
   * User clicks "View all products" CTA on homepage or space page
   * @param screenName
   */
  viewAllProductsClick(screenName: allProductPages) {
    const eventName = 'view_all_products_click'
    const eventObject = {
      screen_name: screenName,
    }
    this.trackAmplitudeEvent(eventName, eventObject)
  }

  /**
   * User clicks on property's logo in the co-branded module
   * @param propertyUrl
   */
  propertyLogoClick(propertyUrl: string) {
    const eventName = 'property_logo_click'
    const eventObject = {
      property_url: propertyUrl,
    }
    this.trackAmplitudeEvent(eventName, eventObject)
  }

  /**
   * User clicks CTA in footer from any page
   * @param buttonName Button clicked
   * @param screenName Screen on which button was clicked in footer
   */
  footerClickEvent(buttonName: string, screenName: string) {
    const eventName = 'footer_click'
    const eventObject = {
      screen_name: screenName,
      button_name: buttonName,
    }
    this.trackAmplitudeEvent(eventName, eventObject)
  }

  /**
   * User clicks Apply on the brand filter dropdown
   * @param filterType
   * @param filterSelected
   * @param screenName
   * @param spaceName
   */
  brandFilterClick(filterType: string = 'brand', filterSelected: string[], screenName: string, spaceName?: string) {
    const eventName = 'brand_filter_click'
    const eventObject = {
      screen_name: screenName,
      space_name: spaceName,
      filter_type: filterType,
      filter_selected: filterSelected,
    }
    this.trackAmplitudeEvent(eventName, eventObject)
  }

  /**
   * User clicks on option in global nav on desktop or within hamburger menu on mobile
   * @param location
   * @param optionSelected
   */
  navClickEvent(location: "global_nav" | "space_switcher" | "hamburger_menu", optionSelected: string) {
    const eventName = 'nav_click'
    const eventObject = {
      location: location,
      option_selected: optionSelected,
    }
    this.trackAmplitudeEvent(eventName, eventObject);
  }

  /**
   * User clicks on arrow to see more items in the carousel
   * @param spaceName
   */
  productCarouselNextClick(spaceName: string) {
    const eventName = 'product_carousel_arrow_click'
    const eventObject = {
      space_name: spaceName,
    }
    this.trackAmplitudeEvent(eventName, eventObject)
  }

  /**
   * User clicks a product card to open the item drawer on mobile
   * @param productData Product Data
   */
  productDrawerImpression(productData: Product) {
    const eventName = 'product_drawer_impression'
    const eventObject = {
      product_name: productData.productName,
      url: productData.affiliateUrl ? productData.affiliateUrl : productData.productUrl,
      website_brand_name: productData.brandName,
    }
    this.trackAmplitudeEvent(eventName, eventObject)
  }

  /**
   * User clicks share button from product drawer on mobile
   * @param productData Product Data
   */
  productDrawerShareClick(productData: Product) {
    const eventName = 'product_drawer_share_click'
    const eventObject = {
      product_name: productData.productName,
      url: productData.affiliateUrl ? productData.affiliateUrl : productData.productUrl,
      website_brand_name: productData.brandName,
    }
    this.trackAmplitudeEvent(eventName, eventObject)
  }

  /**
   * User clicks shop now button from product drawer on mobile
   * @param productData Product Data
   */
  productDrawerShopNowClick(productData: Product) {
    const eventName = 'product_shop_now_click'
    const eventObject = {
      product_name: productData.productName,
      url: productData.affiliateUrl ? productData.affiliateUrl : productData.productUrl,
      website_brand_name: productData.brandName,
    }
    this.trackAmplitudeEvent(eventName, eventObject);
    if(!environment.isPreview) {
      this.homePageService.clickAndViewerUpdate(this.clickAndViewPostData('click')).subscribe();
    }
  }

  /**
   * User clicks "Found in <Space Name>" button from product drawer on mobile
   * @param productData Product Data
   * @param spaceName Space Name
   */
  productDrawerSpaceClick(productData: Product, spaceName: string) {
    const eventName = 'product_drawer_space_click'
    const eventObject = {
      product_name: productData.productName,
      url: productData.affiliateUrl ? productData.affiliateUrl : productData.productUrl,
      website_brand_name: productData.brandName,
      space_name: spaceName
    }
    this.trackAmplitudeEvent(eventName, eventObject)
  }

  /**
   * User clicks on arrow in a product drawer to see the next product
   */
  productDrawerArrowClick() {
    const eventName = 'product_drawer_arrow_click'
    const eventObject = {

    }
    this.trackAmplitudeEvent(eventName, eventObject)
  }

  /**
   * Set posting data for click and viewer update
   * @param key
   */
  clickAndViewPostData(key: string) {
    const deviceId = amplitude.getDeviceId()!;
    return {
      propertyId: this.footerService?.PropertyDetails?.id,
      key: key,
      deviceId: deviceId,
    }
  }

  //------------Amplitude Events for Showroom Gallery Entry Points START----------------

  /**
   * To track showroom entry events
   */
  showroomEntryEvents = {

    /**
     * User clicks on "Book Your Stay" button
     * @param placement
     */
    bookYourStayClickEvent: (placement: string) => {
      const eventName = 'book_a_stay_click';
      const propertyDetails = this.homePageService.PROPERTY_DETAILS!;

      const eventObject = {
        screen_name: this.homePageService.ACTIVE_SCREEN,
        placement,
        property_id: propertyDetails.propertyId,
        account_id: propertyDetails.companyId,
        property_listing_url: propertyDetails?.showroomDetails.propertyLogoLink,
      }

      this.trackAmplitudeEvent(eventName, eventObject)
    },

    /**
     * User click on showroom card inside "More to Explore" section
     * @param showroom
     */
    morePropertiesShowroomCardClick: (showroom: Showroom) => {
      const eventName = 'more_properties_showroom_card_click';
      const propertyDetails = this.homePageService.PROPERTY_DETAILS!;

      const eventObject = {
        property_id: propertyDetails.propertyId,
        account_id: propertyDetails.companyId,
        linked_property_id: showroom.id,
        linked_showroom_name: showroom.showroomName,
      }
      this.trackAmplitudeEvent(eventName, eventObject)
    },

    /**
     * User clicks on arrow in showroom card inside "More to Explore" section
     * @param showroom
     */
    morePropertiesShowroomCardArrowClick: (showroom: Showroom) => {
      const eventName = 'more_properties_showroom_card_arrow_click';
      const propertyDetails = this.homePageService.PROPERTY_DETAILS!;

      const eventObject = {
        property_id: propertyDetails.propertyId,
        account_id: propertyDetails.companyId,
        linked_property_id: showroom.id,
        linked_showroom_name: showroom.showroomName,
      }
      this.trackAmplitudeEvent(eventName, eventObject)
    },

    /**
     * User clicks on carousel arrow in "More to Explore" section
     */
    morePropertiesShowroomCarouselArrowClick: () => {
      const eventName = 'more_properties_showroom_carousel_arrow_click';
      const propertyDetails = this.homePageService.PROPERTY_DETAILS!;

      const eventObject = {
        property_id: propertyDetails.propertyId,
        account_id: propertyDetails.companyId,
      }
      this.trackAmplitudeEvent(eventName, eventObject)
    },

  }

  //------------Amplitude Events for Showroom Gallery Entry Points END----------------
}
