import {Injectable, signal, WritableSignal} from '@angular/core';
import {BreakpointObserver} from "@angular/cdk/layout";

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  isMobile: WritableSignal<boolean> = signal(false);

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {

  }

  /**
   * To start the resize observer
   */
  startObserver() {
    this.breakpointObserver.observe('(max-width: 767px)').pipe().subscribe({
      next: (result) => {
        this.isMobile.set(result.matches);
      }
    })
  }

}
