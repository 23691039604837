import {Inject, Injectable, PLATFORM_ID, signal, WritableSignal} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {BehaviorSubject, catchError, firstValueFrom, map, Observable, take} from "rxjs";
import {PropertyDetails} from "../../interfaces/propertyDetails";
import {Meta, Title} from "@angular/platform-browser";
import {SkimlinksService} from "../tracking/skimlinks.service";
import {isPlatformBrowser} from "@angular/common";
import {FooterService} from "../footer/footer.service";
import {Router} from "@angular/router";
import {SpaceDetails} from "../../interfaces/spaceDetails";
import AOS from "aos";
import {Showroom} from "../../interfaces/showroom.interface";

@Injectable({
  providedIn: 'root'
})
export class HomepageService {

  constructor(
    private http: HttpClient,
    public title: Title,
    public meta: Meta,
    public skimlinksService: SkimlinksService,
    @Inject(PLATFORM_ID) public platformId: Object,
    public footerService: FooterService,
    public router: Router,
  ) {
  }


  HOMEPAGE_DETAILS = new BehaviorSubject<PropertyDetails | null>(null);

  SHOWROOM_LIST: WritableSignal<Showroom[]> = signal([]);

  SHOW_BOOK_A_STAY: WritableSignal<boolean> = signal(false);

  isPageLoad = new BehaviorSubject<boolean>(false);

  SELECTED_SPACE_DETAILS = new BehaviorSubject<SpaceDetails | undefined>(undefined);

  /**
   * To stop/enable scroll on route navigate
   */
  SHOULD_SCROLL = new BehaviorSubject<boolean>(true)

  CLICK_PRODUCT_ID = new BehaviorSubject<string>('')


  _IS_IFRAME = new BehaviorSubject<boolean>(environment.isPreview)

  ACTIVE_SCREEN = 'homepage';

  /**
   * [GET] Request to fetch homepage info
   * @param propertyId - unique property id
   */
  getBasePageInfo(propertyId: string): Observable<PropertyDetails> {
    let params = new HttpParams();
    params = params.append('newShowroomUrl', propertyId);
    params = params.append('isPreview', this.IS_IFRAME);
    return this.http.get(`${environment.apiUrl}/showroom/homepage/details`, {params})
      .pipe(take(1), map((response: any) => {
        this.HOMEPAGE_DETAILS.next(response.data)
        this.isPageLoad.next(true);
        const data: PropertyDetails = response.data
        const showroomDetails = data.showroomDetails
        this.updateMetaAndTitleTags(`Shop Your Stay | ${showroomDetails?.showroomName!}`, showroomDetails?.image!)
        this.footerService.propertyId.next(propertyId);
        this.footerService.propertyDetails.next({id: data.propertyId, companyId: data.companyId})
        if(isPlatformBrowser(this.platformId)) {
          this.skimlinksService.loadAffliateLinks(`${propertyId}-${response.data.propertyId}`);
        }
        console.log(response.data)
        return response.data;
      }), catchError((err) => {
        this.isPageLoad.next(true);
        throw err
      }));
  }


  get PROPERTY_DETAILS(): PropertyDetails | null {
    return this.HOMEPAGE_DETAILS.getValue()
  }

  get isPageLoaded(): boolean {
    return this.isPageLoad.getValue();
  }

  /**
   * Fetch details of current property ID
   * @param propertyId - Current propertyId
   */
  fetchHomepageDetails(propertyId: string) {
    this.getBasePageInfo(propertyId).subscribe({
      next: (response) => {
        console.log(response)
      },
      error: (error) => {

      }
    })
  }

  /**
   * Async function to get property details based on property ID
   * @param propertyId Property ID
   */
  async fetchPageInformation(propertyId: string) {
    await firstValueFrom(this.getBasePageInfo(propertyId)).catch(() => {
      this.router.navigate(['/error'])
    });
  }

  /**
   * [GET] Request to fetch homepage info
   * @param propertyId - unique property id
   */
  getShowroomList(propertyId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('newShowroomUrl', propertyId);
    params = params.append('isPreview', this.IS_IFRAME);
    return this.http.get(`${environment.apiUrl}/homepage/showroom/list`, {params})
      .pipe(take(1), map((response: any) => {
        console.log(response.data.showroomList);
        this.SHOWROOM_LIST.set(response.data.showroomList)
        return response.data;
      }), catchError((err) => {
        throw err
      }));
  }

  async fetchShowroomList(propertyId: string) {
    await firstValueFrom(this.getShowroomList(propertyId)).catch(() => {
      console.error('Unable to Fetch Showroom List')
    });
  }

  /**
   * Update Meta and Title Tags
   * @param title Page title
   * @param image Page hero image
   */
  updateMetaAndTitleTags(title: string, image: string) {

    const routerUrl = this.router.url;
    const currentUrl = `${environment.websiteUrl}/shop${routerUrl}`;

    // URL
    this.addOrEditMetaTag({property: 'og:url', content: currentUrl})
    this.addOrEditMetaTag({property: 'twitter:url', content: currentUrl})

    // Title
    this.title.setTitle(title);
    this.addOrEditMetaTag({property: 'title', content: title});
    this.addOrEditMetaTag({property: 'twitter:title', content: title});
    this.addOrEditMetaTag({property: 'og:title', content: title});

    // Hero image
    this.addOrEditMetaTag({property: 'og:image', content: image});
    this.addOrEditMetaTag({property: 'twitter:image', content: image});


    // Description
    const description = 'Bring home a piece of your stay with Minoan. Explore carefully curated home furnishings and decor and shop the products you want to take home with you.'
    this.addOrEditMetaTag({property: 'description', content: description});
    this.addOrEditMetaTag({property: 'og:description', content: description});
    this.addOrEditMetaTag({property: 'twitter:description', content: description});

    // Random
    this.addOrEditMetaTag({property: 'twitter:card', content: 'summary_large_image'});
    this.addOrEditMetaTag({property: 'og:type', content: 'website'});
    this.addOrEditMetaTag({property: 'twitter:domain', content: environment.minoanWebsiteUrl.replaceAll('https://www.', '')})

    this.addEditCanonical({rel: 'canonical', content: currentUrl})
  }

  /**
   * If meta tag exists, modifies existing tag content, else creates a new meta tag with the supplied information
   * @param information
   */
  addOrEditMetaTag(information: {property: string, content: string}) {
    const metaTag = this.meta.getTag(`property='${information.property}'`)
    if(metaTag) {
      if(information.content) {
        this.meta.removeTag(`property='${information.property}'`)
        this.meta.addTag(information)
      } else {
        this.meta.removeTag(`property='${information.property}'`)
      }
    } else {
      this.meta.addTag(information)
    }
  }

  /**
   * Add/edit canonical meta tag
   * @param information
   */
  addEditCanonical(information: {rel: string, content: string}) {
    const metaTag = this.meta.getTag(`rel='${information.rel}'`)
    if(metaTag) {
      if(information.content) {
        this.meta.removeTag(`rel='${information.rel}'`)
        this.meta.addTag(information)
      } else {
        this.meta.removeTag(`rel='${information.rel}'`)
      }
    } else {
      this.meta.addTag(information)
    }
  }

  get selectedSpace() {
    return this.SELECTED_SPACE_DETAILS.getValue()
  }

  set selectedSpace(space) {
    this.SELECTED_SPACE_DETAILS.next(space)
  }

  /**
   * Getter for should scroll
   */
  get shouldScroll(): boolean {
    return this.SHOULD_SCROLL.getValue()
  }

  /**
   * Setter for should scroll
   * @param scroll Value
   */
  set shouldScroll(scroll) {
    this.SHOULD_SCROLL.next(scroll)
  }

 get productToClick() {
    return this.CLICK_PRODUCT_ID.getValue()
 }

  /**
   * To init AOS when app starts on browser
   */
  initAOS() {
    AOS.init({
      duration: 2500, once: true,
    });
  }

  /**
   * To hard refresh AOS
   */
  refreshAOS() {
    AOS.refresh();
  }

  get IS_IFRAME(): boolean {
    return this._IS_IFRAME.getValue()
  }

  set IS_IFRAME(value: boolean) {
	  this._IS_IFRAME.next(value)
  }

  /**
   * [POST] Request to increase count and viewer click
   * @param postData
   */
  clickAndViewerUpdate(postData: { propertyId: number, key: string, deviceId: string }): any {
    return this.http.post(`${environment.apiUrl}/showroom/click/viewer/update`, postData).pipe(
      take(1), map((response: any) => response.data), catchError(err => {throw err})
    )
  }

  /**
   * [POST] Request to update viewer count
   * @param requestBody Request body
   */
  updateViewerCount(requestBody: {propertyId: number, deviceId: string}) {
    return this.http.post(`${environment.apiUrl}/showroom/viewer/update`, requestBody).pipe(
      take(1), map((response: any) => response.data), catchError(err => {throw err})
    ).subscribe({
      next: () => {
      },
      error: () => {}
    })
  }


  /**
   * [POST] Request to stop tracking device ID
   * @param deviceId
   */
  stopTrackingDeviceId(deviceId:string) {
    const requestBody = {deviceId: deviceId, key: 'showroom'}
    return this.http.post(`${environment.apiUrl}/device/blacklist`, requestBody).pipe(
      take(1), map((response: any) => response.data), catchError(err => {throw err})
    )
  }
}
